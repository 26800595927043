<template>
  <section>
    <vue-headful title="FLPN Aviasolutions - List Users"></vue-headful>
    <h1 class="title">List Users</h1>
    <contenedor>
      <b-table
          paginated
          backend-pagination
          backend-sorting
          :data="pagination.records"
          :loading="pagination.loading"
          :total="pagination.total"
          :per-page="pagination.perPage"
          :default-sort-direction="pagination.defaultSortOrder"
          :default-sort="[pagination.sortField, pagination.sortOrder]"
          @page-change="onPageChange"
          @sort="onSort">
        <template slot-scope="props">
          <b-table-column field="first_name" label="Full Name" sortable>
            {{ props.row.full_name }}
          </b-table-column>
          <b-table-column field="email" label="Email" sortable>
            {{ props.row.email }}
          </b-table-column>
          <b-table-column field="license_number" label="License Number" sortable>
            {{ props.row.license_number }}
          </b-table-column>
          <b-table-column field="client" label="Client">
            {{ props.row.client ? props.row.client.name : '-' }}
          </b-table-column>
          <b-table-column width="80">
            <div class="field has-addons">
              <p class="control">
                <router-link :to="`/users/${props.row.id}/edit`" class="button is-small is-primary">
                  <b-icon pack="far" icon="edit"></b-icon>
                </router-link>
              </p>
              <p class="control">
                <button type="button"
                        class="button is-small is-danger"
                        v-on:click="onDeleteUser(props.row)">
                  <b-icon pack="far" icon="trash-alt"></b-icon>
                </button>
              </p>
            </div>
          </b-table-column>
        </template>
      </b-table>
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </contenedor>
  </section>
</template>
<script>
import UsersService from "../../services/users.service.js";
import Contenedor from "@/components/Contenedor";

export default {
  name: "UsersIndex",
  components: {Contenedor},
  data() {
    return {
      isLoading: false,
      usersService: new UsersService(),
      pagination: {
        records: [],
        total: 0,
        loading: false,
        sortField: "first_name",
        sortOrder: "asc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 20
      }
    };
  },
  methods: {
    paginate() {
      const params = {
        sort_field: this.pagination.sortField,
        sort_order: this.pagination.sortOrder,
        per_page: this.pagination.perPage,
        page: this.pagination.page
      };
      this.pagination.loading = true;
      this.usersService.paginate(params).then(
          ({data}) => {
            // api.themoviedb.org manage max 1000 pages
            this.pagination.records = data.data;            
            this.pagination.total = data.meta.total;            
            this.pagination.loading = false
          },
          () => {
            this.pagination.records = []
            this.pagination.total = 0
            this.pagination.loading = false
            this.$buefy.toast.open({
              message: 'An error ocurred while paginating!!!',
              position: 'is-bottom',
              type: 'is-danger'
            });
          }
      );
    },
    /*
    * Handle page-change event
    */
    onPageChange(page) {
      this.pagination.page = page;
      this.paginate();
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.pagination.sortField = field;
      this.pagination.sortOrder = order;
      this.paginate();
    },
    onDeleteUser(user) {
      this.$buefy.dialog.confirm({
        title: 'Deleting user',
        message: `This will delete the user: <b>${user.full_name}</b> from the database, are you sure?`,
        cancelText: 'Cancel',
        confirmText: 'Yes, delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          this.usersService.delete(user.id).then(
              ({data}) => {
                this.isLoading = false;
                this.$buefy.toast.open({
                  message: data.notificacion,
                  type: 'is-success'
                });
                this.paginate();
              },
              () => {
                this.isLoading = false;
                this.$buefy.toast.open({
                  message: 'An error ocurred while deleting the user.',
                  type: 'is-danger'
                });
              }
          );
        }
      })
    }
  },
  mounted() {
    this.paginate();
  }
}
</script>
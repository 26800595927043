import ApiService from "./api.service"

export default class UsersService extends ApiService
{
    path = 'users';
    findRoles() {
        return this.http.get(`${this.url}/roles`);
    }

    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }
}